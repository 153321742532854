<template>

    <v-img v-if="full" :src="src" :alt="alt" :style="'max-height: ' + height + 'px;'">
        <h-row class="mt-4 pr-4">
            <v-spacer></v-spacer>
            <h-col cols="5">
                <slot></slot>
            </h-col>
        </h-row>
    </v-img>

    <h-row v-else align="center" :style="'max-height: ' + height + 'px;'">
        <h-col v-if="inverse" cols="6" no-padding>
            <slot></slot>
        </h-col>
        <h-col class="py-6" cols="6">
            <v-img class="rounded-lg"
                   style="margin: auto; border: 1px solid #000000"
                   :src="src"
                   :alt="alt"
                   max-height="550"
                   max-width="320"
            ></v-img>
        </h-col>
        <h-col v-if="!inverse" cols="6" no-padding>
            <slot></slot>
        </h-col>
    </h-row>

</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';

    export default {
        name: 'IndexSezione',
        components: {HRow, HCol},
        props: {src: String, alt: String, full: Boolean, inverse: Boolean},
        computed: {
            height: function () {
                return window.innerHeight - 60;
            }
        }
    }
</script>

<style scoped>

</style>
