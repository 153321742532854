<template>
    <h-row justify="center">
        <h-col no-padding>
            <index-sezione src="/assets/HOME_COPERTINA.jpg" alt="hand2hand-background" full>
                <h-row justify="center">
                    <v-img src="/assets/LOGO.png"
                           alt="hand2hand-logo"
                           class="mb-2 mb-sm-4"
                           max-width="30%"
                           max-height="20%"
                    ></v-img>
                </h-row>
                <h-row justify="center">
                    <h-col cols="auto" class="text-center">
                        <i>Scrarica la nostra app gratuita sullo store</i>
                    </h-col>
                </h-row>
                <h-row class="mb-2 mb-sm-4" justify="center">
                    <v-img src="/assets/PLAYSTORE.png"
                           alt="hand2hand-playstore"
                           style="cursor: pointer;"
                           width="100%"
                           max-width="450px"
                           @click.stop="onClickPlayStore"
                    ></v-img>
                </h-row>
                <h-row class="pb-6" justify="center">
                    <v-img src="/assets/APPSTORE.png"
                           alt="hand2hand-appstore"
                           style="cursor: pointer;"
                           width="100%"
                           max-width="450px"
                           @click.stop="onClickAppStore"
                    ></v-img>
                </h-row>
            </index-sezione>
            <index-sezione class="warning" src="/assets/SCREEN_FACILE.jpg" alt="hand2hand-screen-news">
                <h-row justify="center">
                    <h-col cols="auto" class="text-center white--text">
                        <h1 class="pb-4">FACILE</h1>
                        <i>L'utilizzo dell'app risulta facile e intuitivo</i>
                    </h-col>
                </h-row>
            </index-sezione>
            <index-sezione src="/assets/SCREEN_VELOCE.jpg" alt="hand2hand-screen-news" inverse>
                <h-row justify="center">
                    <h-col cols="auto" class="text-center">
                        <h1 class="pb-4">VELOCE</h1>
                        <i>Bastano pochi passaggi per trovare l'evento giusto per te</i>
                    </h-col>
                </h-row>
            </index-sezione>
            <index-sezione class="warning" src="/assets/SCREEN_AFFIDABILE.jpg" alt="hand2hand-screen-news">
                <h-row justify="center">
                    <h-col cols="auto" class="text-center white--text">
                        <h1 class="pb-4">AFFIDABILE</h1>
                        <i>Non dovrai più temere per la privacy</i><br>
                        <i>l'app non registra nessun dato personale privato</i>
                    </h-col>
                </h-row>
            </index-sezione>
            <h-row justify="space-around" class="pa-6">
                <index-footer class="mb-6" title="FAQ" icon="fa-circle-question" to="/app/faq"></index-footer>
                <index-footer class="mb-6" title="PRIVACY" icon="fa-key" to="/app/privacy"></index-footer>
                <index-footer title="CHANGELOG" icon="fa-bullhorn" to="/app/changelog/last"></index-footer>
            </h-row>
            <h-row class="black white--text pa-4" justify="center">
                <h-col cols="9">
                    <h2>Bacchini Marco</h2>
                </h-col>
                <h-col cols="9" class="px-3" no-padding>
                    <a href="mailto:info@hand-2-hand.it"><i>info@hand-2-hand.it</i></a>
                </h-col>
                <h-col cols="9">
                    <a href="tel:+39 3385714443"><i>+39 3385714443</i></a>
                </h-col>
            </h-row>
        </h-col>
    </h-row>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';
    import IndexSezione from '@/components/index/IndexSezione';
    import IndexFooter from '@/components/index/IndexFooter';

    export default {
        name: 'Index',
        components: {HRow, HCol, IndexFooter, IndexSezione},
        methods: {
            onClickPlayStore() {
                window.open('https://play.google.com/store/apps/details?id=com.h2h.it', '_blank').focus();
            },
            onClickAppStore() {
                window.alert('App presto disponibile');
            }
        }
    }
</script>

<style scoped>

</style>
