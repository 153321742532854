<template>
    <h-col cols="auto" style="min-width: 250px;" no-padding>
        <v-btn block height="200" :to="to" exact>
            <h-row justify="center" align="center">
                <h-col no-padding>
                    <h-row justify="center" align="center">
                        <h-col cols="12" no-padding>
                            <h1 class="pb-4 text-center">
                                {{ title }}
                            </h1>
                        </h-col>
                    </h-row>
                    <h-row justify="center" align="center">
                        <h-col cols="auto" no-padding>
                            <v-icon class="pb-2" size="80">
                                {{ icon }}
                            </v-icon>
                        </h-col>
                    </h-row>
                </h-col>
            </h-row>
        </v-btn>
    </h-col>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';

    export default {
        name: 'IndexFooter',
        components: {HRow, HCol},
        props: {title: String, icon: String, to: String}
    }
</script>

<style scoped>

</style>
